var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        { staticStyle: { margin: "30px 0 10px 0", "padding-left": "40px" } },
        [
          _c("span", { staticStyle: { "margin-right": "40px" } }, [
            _vm._v("供应商："),
            _c("span", { staticStyle: { color: "#538FF4" } }, [
              _vm._v(_vm._s(_vm._f("hideTextInfo")(_vm.data.clientName, 3, 4))),
            ]),
          ]),
          _c("span", { staticStyle: { "margin-right": "40px" } }, [
            _vm._v(
              "联系人：" +
                _vm._s(_vm._f("hideTextInfo")(_vm.data.clientUserName, 1, 0)) +
                " " +
                _vm._s(_vm._f("hideTextInfo")(_vm.data.tel, 3, 4))
            ),
          ]),
          _c("span", [
            _vm._v(
              "所在地：" +
                _vm._s(_vm._f("hideTextInfo")(_vm.data.offAddr, 3, 4))
            ),
          ]),
        ]
      ),
      _vm._l(_vm.data.cartGoodsVoList, function (item) {
        return _c("div", { key: item.id, staticClass: "table-wrap" }, [
          _c(
            "div",
            { staticClass: "first-column" },
            [
              _c("el-checkbox", {
                staticStyle: {
                  position: "absolute",
                  left: "14px",
                  top: "40px",
                },
                on: {
                  change: function ($event) {
                    return _vm.change(item, item.id)
                  },
                },
                model: {
                  value: item.checked,
                  callback: function ($$v) {
                    _vm.$set(item, "checked", $$v)
                  },
                  expression: "item.checked",
                },
              }),
              _c(
                "div",
                { staticStyle: { "margin-right": "20px" } },
                [
                  _c("el-image", {
                    staticStyle: { width: "102px", height: "102px" },
                    attrs: { src: item.picUrl, alt: "", fit: "fit" },
                  }),
                ],
                1
              ),
              _c(
                "div",
                [
                  _c(
                    "el-tooltip",
                    {
                      staticClass: "item",
                      attrs: {
                        effect: "dark",
                        content: item.name,
                        placement: "top",
                      },
                    },
                    [
                      _c("p", { staticClass: "product-name omit" }, [
                        _vm._v(" " + _vm._s(item.name) + " "),
                      ]),
                    ]
                  ),
                  _c(
                    "div",
                    { staticStyle: { display: "flex", "margin-top": "20px" } },
                    [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            width: "180px",
                            flex: "none",
                            "margin-right": "20px",
                          },
                        },
                        [
                          _c(
                            "p",
                            {
                              staticClass: "omit",
                              staticStyle: {
                                "margin-bottom": "5px",
                                color: "#999",
                              },
                            },
                            [_vm._v(_vm._s(item.entrepot))]
                          ),
                          _c("p", [
                            _c("span", { staticStyle: { color: "#999" } }, [
                              _vm._v("货物编号："),
                            ]),
                            _c("span", { staticStyle: { color: "#3F3F3F" } }, [
                              _vm._v(_vm._s(item.goodsSn)),
                            ]),
                          ]),
                        ]
                      ),
                      _c("div", [
                        _c(
                          "p",
                          { staticStyle: { "margin-bottom": "5px" } },
                          [
                            _c("span", { staticStyle: { color: "#999" } }, [
                              _vm._v("规格："),
                            ]),
                            item.specifications == null
                              ? _c(
                                  "span",
                                  { staticStyle: { color: "#3F3F3F" } },
                                  [_vm._v("无")]
                                )
                              : _vm._l(item.specifications, function (spec) {
                                  return _c(
                                    "span",
                                    { staticStyle: { color: "#3F3F3F" } },
                                    [_vm._v("[" + _vm._s(spec) + "] ")]
                                  )
                                }),
                          ],
                          2
                        ),
                        _c("p", [
                          _c("span", { staticStyle: { color: "#999" } }, [
                            _vm._v("材质："),
                          ]),
                          _c("span", { staticStyle: { color: "#3F3F3F" } }, [
                            _vm._v(_vm._s(item.material)),
                          ]),
                        ]),
                      ]),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("div", { staticClass: "second-column" }, [
            _c(
              "h3",
              {
                staticClass: "omit",
                staticStyle: { "text-align": "center", "margin-top": "40px" },
              },
              [_vm._v(_vm._s(item.manufacturer))]
            ),
          ]),
          _c("div", { staticClass: "second-column" }, [
            _c(
              "h3",
              {
                staticClass: "omit",
                staticStyle: { "text-align": "center", "margin-top": "40px" },
              },
              [
                _vm._v(
                  _vm._s(
                    isNaN(item.weight) || item.weight == 0
                      ? "-"
                      : item.weight * item.number + item.units
                  )
                ),
              ]
            ),
          ]),
          _c("div", { staticClass: "second-column" }, [
            _c(
              "h3",
              {
                staticClass: "omit",
                staticStyle: {
                  "text-align": "center",
                  "margin-top": "40px",
                  "font-weight": "500",
                },
              },
              [_vm._v(_vm._s(item.number))]
            ),
          ]),
          _c("div", { staticClass: "second-column" }, [
            _c(
              "h3",
              {
                staticClass: "omit",
                staticStyle: {
                  "text-align": "center",
                  "margin-top": "40px",
                  "font-weight": "500",
                  color: "red",
                },
              },
              [
                _vm._v(
                  "￥" + _vm._s(parseFloat(item.price * item.number).toFixed(2))
                ),
              ]
            ),
          ]),
          _c("div", { staticClass: "second-column" }, [
            _c(
              "h3",
              { staticStyle: { "margin-top": "40px" } },
              [
                _c(
                  "el-button",
                  {
                    attrs: { type: "text" },
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.deleted(item.id)
                      },
                    },
                  },
                  [_vm._v("删除")]
                ),
              ],
              1
            ),
          ]),
        ])
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }