<template>
    <div>
        <div style="margin:30px 0 10px 0;padding-left:40px">
            <span style="margin-right:40px">供应商：<span style="color: #538FF4">{{data.clientName | hideTextInfo(3, 4)}}</span></span>
            <span style="margin-right:40px">联系人：{{data.clientUserName | hideTextInfo(1, 0)}} {{data.tel | hideTextInfo(3, 4)}}</span>
            <span>所在地：{{data.offAddr | hideTextInfo(3, 4)}}</span>
        </div>

        <div class="table-wrap" v-for="item in data.cartGoodsVoList" :key="item.id">
            <div class="first-column">
                <el-checkbox v-model="item.checked"  @change='change(item,item.id)' style="position:absolute;left:14px;top:40px"></el-checkbox>
                <div style="margin-right:20px">
                    <!-- <el-image :src="data.imgUrl" alt="" ></el-image> -->
                    <el-image :src="item.picUrl" alt="" fit="fit" style="width:102px;height:102px;"></el-image>
                </div>
                <div>
                    <el-tooltip class="item" effect="dark" :content="item.name" placement="top">
                        <p class="product-name omit">
                        {{item.name}}
                        </p>
                    </el-tooltip>
                        <!-- {{item.goodsName}} -->
                    <div style="display:flex;margin-top:20px">
                        <div style="width:180px;flex:none;margin-right:20px">
                            <p class="omit" style="margin-bottom:5px;color:#999;">{{item.entrepot}}</p>
                            <p>
                                <span style="color:#999">货物编号：</span>
                                <span style="color: #3F3F3F">{{item.goodsSn}}</span>
                            </p>
                        </div>
                        <div>
                            <p style="margin-bottom:5px;">
                                <span style="color:#999">规格：</span>
                                <span style="color: #3F3F3F" v-if="item.specifications==null">无</span>
                                <span style="color: #3F3F3F" v-else v-for="spec in item.specifications">[{{spec}}] </span>
                            </p>
                            <p>
                                <span style="color:#999">材质：</span>
                                <span style="color: #3F3F3F">{{item.material}}</span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="second-column">
                <h3 class="omit" style="text-align:center;margin-top:40px;">{{item.manufacturer}}</h3>
            </div>
            <!--<div class="second-column">-->
            <!--    <h3 class="omit" style="text-align:center;margin-top:40px;">{{item.mode}}</h3>-->
            <!--</div>-->
            <div class="second-column">
                <h3 class="omit" style="text-align:center;margin-top:40px;">{{(isNaN(item.weight)||item.weight==0)?'-':item.weight*item.number+item.units}}</h3>
            </div>
            <div class="second-column">
                <h3 class="omit" style="text-align:center;margin-top:40px;font-weight:500">{{item.number}}</h3>
            </div>
            <div class="second-column">
                <h3 class="omit" style="text-align:center;margin-top:40px;font-weight:500;color:red">￥{{parseFloat(item.price *item.number).toFixed(2)}}</h3>
            </div>
            <div class="second-column">
                <h3 style="margin-top:40px;">
                    <el-button type="text" @click.stop="deleted(item.id)">删除</el-button>
                </h3>
            </div>
        </div>
    </div>
</template>

<script>
    import { hideTextInfo } from '@/utils/util';
    export default {
        props: [
            'data'
        ],
        data() {
            return {
                imgurl: require('@/assets/moren.jpg'),
                checked:{
                    checked: {}
                }
            }
        },
        filters: {
            hideTextInfo
        },
        methods: {
            getId(id) {
                this.$emit('getCid', id);
            },

            // 选中
            change(item,id) {
                this.$emit('select', item);
            },

            // 点击删除
            deleted(id) {
                this.$emit('getCid', id);
            }
        }
    }
</script>

<style lang="less" scoped>
    .table-wrap {
        display: flex;
        padding: 15px 0px;
        background: #fff;
        border-bottom: 1px solid #F4F4F4;
        align-items: center;
        .first-column {
            display: flex;
            width: 540px;
            padding-left: 40px;
            box-sizing: border-box;
            position: relative;
        }

        .second-column {
            width: 132px;
            text-align: center
        }

    }

    .product-name {
        font-weight: bold;
        font-size: 20px;
        margin: 10px 0;
        width:380px
    }
</style>
