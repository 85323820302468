<template>
    <div style="width:1200px;margin:0 auto;padding:20px 0">
        <el-breadcrumb separator="/">
            <el-breadcrumb-item :to="{ path: '/home/HomePage' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item :to="{ path: '/home/Mall' }">商品超市</el-breadcrumb-item>
            <el-breadcrumb-item>我的采购单</el-breadcrumb-item>
        </el-breadcrumb>

        <div>
            <h1>我的意向订单
                <span style="float:right;font-size:16px;font-weight:500;line-height:30px;color: #1F86E3;cursor:pointer;"
                    @click="$router.push('/home/Mall')">
                    继续选购资源>>
                </span>
            </h1>
            <div style="background:#E8E8E8;display:flex;text-align:center;padding:15px 0;">
                <div v-for="(item,index) in title" :key="index" :style="{'width':item.width,'font-size': '16px'}">
                    {{item.name}}</div>
            </div>
            <NoDataFound v-if="cartGoods.length == 0"></NoDataFound>
            <TableRow v-else v-for="item in cartGoods" :data='item' :key="item.id" @select="modifyPrice" @getCid='cartDelete'></TableRow>
            <div style="background:#fff;height:70px;margin-top:20px;position:relative" v-if="cartGoods.length>0">
                <div style="position: absolute;right:0;display:flex;">
                    <div style="margin-right:20px;text-align:right;padding-top:10px">
                        <p style="margin:5px 0">合计：<span style="color:#FF4805;font-weight:bold;font-size:16px ">{{cartTotal.checkedGoodsAmount.toFixed(2)}}</span>元</p>
                        <!--<p >转货费：0.00元</p>-->
                    </div>
                    <div style="cursor:pointer;background: linear-gradient(to right,#F57838,#FF4603);line-height:70px;padding:0 30px;color:#fff;font-size:22px"
                        @click="buy">
                        立即购买
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import TableRow from '../component/TableRow'
    import NoDataFound from "@/components/NoDataFound.vue";
    import {cartList,deleteCart,getCartCount,checkedCart} from "../api";
    export default {
        data() {
            return {
                title: [{
                    name: '商品信息',
                    width: '540px'
                }, {
                    name: '钢厂',
                    width: '132px'
                }
                // , {
                //     name: '计算方式',
                //     width: '110px'
                // }
                , {
                    name: '重量',
                    width: '132px'
                }, {
                    name: '数量',
                    width: '132px'
                }, {
                    name: '总金额(元)',
                    width: '132px'
                }, {
                    name: '操作',
                    width: '132px'
                }],
                cartGoods:[

                ],
                cartTotal: {
                    goodsCount: 0,
                    checkedGoodsCount: 0,
                    goodsAmount: 0.0,
                    checkedGoodsAmount: 0.0
                },
                list:[],
            }
        },
        methods: {
            buy() {
                this.cartGoods.forEach(goods=>{
                    goods.cartGoodsVoList.forEach(cart=>{
                        if(cart.checked){
                            this.list.push(cart.id)
                        }
                    })
                })
                if(this.list.length>0){
                    this.$router.push({path:'/home/Mall/ShoppingList/ConfirmOrder'})
                }else {
                    this.$message.info("没有选中的意向商品");
                }

                // 普通会眺到 提示页面
                // this.$router.push('/home/Mall/ShoppingList/Result')
            },
            //显示
            show(){
                cartList().then(
                    res => {
                        if(res.errno==0){
                            this.cartGoods=res.data.cartItem
                            this.cartTotal=res.data.cartTotal
                        }else{
                            this.$router.push('/home/Login')
                        }

                    }
                )
            },
            //删除
            cartDelete(id){
                var _this=this;
                this.$confirm('确定删除该条信息吗?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    deleteCart({id:id}).then(
                        res => {
                            this.cartGoods=res.data.cartItem
                            this.cartTotal=res.data.cartTotal
                            _this.$store.commit('setCarCount', this.cartTotal.goodsCount);
                        }
                    )
                })
            },
            //商品选择状态修改,修改价格
            modifyPrice(item){
                checkedCart({id:item.id,isChecked:item.checked}).then(
                    res => {
                        if(res.errno!==0){
                            this.$notify.error({
                                title:"错误",
                                message:"商品修改勾选状态失败"
                            })
                            item.checked=!item.checked
                        }else{
                            this.cartTotal.checkedGoodsAmount=item.checked?
                                this.cartTotal.checkedGoodsAmount+item.price*item.number:
                                this.cartTotal.checkedGoodsAmount-item.price*item.number
                        }
                    }
                )
            }
        },
        components: {
            TableRow,NoDataFound
        },
        created() {
            this.show();
        }
    }
</script>

<style>

</style>
